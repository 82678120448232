.shepherd-header {
  @apply !bg-popover !w-[100%] !p-0;
}

.shepherd-title {
  @apply !text-highlight !w-[100%] !break-words !text-xl !leading-[1.5];
}

.shepherd-content {
  @apply flex flex-col gap-[8px] p-[12px];
}

.shepherd-element {
  @apply !bg-popover !max-w-[260px];
}

.shepherd-text {
  @apply text-foreground !w-[100%] p-0 text-lg leading-normal;
}

.shepherd-footer {
  @apply !w-[100%] p-0;
}

.shepherd-button {
  @apply !inline-flex !h-[36px] !min-w-[62px] !flex-row !items-center !justify-center !gap-[5px] !whitespace-nowrap !rounded !bg-[#348cfd] !px-[10px] !text-center !font-sans !text-[14px] !leading-[1.2] !text-white !outline-none !transition !duration-300 !ease-in-out focus:!outline-none;
}

.shepherd-button.shepherd-button-secondary {
  @apply !bg-transparent !text-[#348cfd];
}

.shepherd-arrow::before {
  @apply !bg-popover !h-[30px] !w-[30px];
}

.shepherd-element[data-popper-placement^='left'] > .shepherd-arrow {
  right: 3px !important;
  top: 6px !important;
}

.shepherd-element[data-popper-placement^='top'] > .shepherd-arrow {
  bottom: 2px !important;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  @apply !opacity-70;
}
